import React, { Component } from 'react';
import './Chat.scss';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

class Chat extends Component {
	minimize = () => {
		this.setState({ minimize: !this.state.minimize })
	};
	constructor(props) {
		super(props);
		this.state = {
			minimize: false
		};
	};
	render() {
		this.hideChat = this.props.hideChat;
		return (
			<div className="chat-wrapper">
				<Icon className="close-icon" path={mdiClose} size={1.5} />
				<iframe title="Chatbox" className={"chatbox" + (this.state.minimize ? " minimize" : "")} height="530" width="350" allow="microphone;" src="https://console.dialogflow.com/api-client/demo/embedded/c5d68901-8e67-4a1d-bbfc-56b2de843ffa"></iframe>
			</div>
		);
	}
}

export default Chat;
