import React, { Component } from 'react';
import './Splash.scss';

import ServiceButton from '../Reusable/ServiceButton';

import { mdiOffice, mdiGoogle, mdiCalculator } from '@mdi/js';
import { Link } from 'react-router-dom';

class Splash extends Component {
	render () {
		return (
			<div className="wrapper">
				<div className="content">
					<span className="title">Total Service Center</span>
					<div className="buttons">
						<Link to="/microsoft">
							<ServiceButton class="microsoft" icon={mdiOffice} size={1} text="M365" />
						</Link>
						<Link to="/gsuite">
							<ServiceButton class="gsuite" icon={mdiGoogle} size={1} text="G Suite" />
						</Link>
					</div>
				</div>
				<div className="footer">
					<span>Ofrecido por Informática El Corte Inglés</span>
				</div>
			</div>
		)
	}
}
export default Splash
