import React, { Component } from 'react';
import './Navbar.scss';

import ServiceButton from '../Reusable/ServiceButton';

import { mdiOffice, mdiGoogle } from '@mdi/js';
import { Link } from 'react-router-dom';

class Navbar extends Component {
	hideChat = this.props.hideChat;
	render() {
		return (
			<nav>
				<Link to="/">
					<div className="title">
						<span>TSC</span>
					</div>
				</Link>
				<Link to="/microsoft">
					<ServiceButton class="microsoft" icon={mdiOffice} size={1} text="M365" />
				</Link>
				<Link to="/gsuite">
					<ServiceButton class="gsuite" icon={mdiGoogle} size={1} text="G Suite" />
				</Link>
				<ServiceButton class="chat-button" text="Chat" onClick={() => this.hideChat(false)} />
			</nav>
		);
	}
}

export default Navbar;
