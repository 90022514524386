import React, { Component } from 'react';
import './App.scss';

import firebase from 'firebase';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navbar from './Navbar/Navbar';
import Splash from './Splash/Splash';
import Chat from './Chat';
import Guide from './Guide/Guide';
import Authentication from './Firebase/Auth';

const config = {
    apiKey: "AIzaSyClvHYT4pmphxcXfxUQ7Cor59L9t_qe5Q4",
    authDomain: "tsc-iecisa.firebaseapp.com",
    databaseURL: "https://tsc-iecisa.firebaseio.com",
    projectId: "tsc-iecisa",
    storageBucket: "tsc-iecisa.appspot.com",
    messagingSenderId: "977248868759"
  };

class App extends Component {
	hideChat = (action) => {
		this.setState({ hideChat: action });
	};
	constructor() {
		super();
		this.state = { hideChat: true };

		firebase.initializeApp(config);
		this.auth = new Authentication();
	}
	render() {
		return (
			<Router>
				<div className="app">
					<Navbar hideChat={this.hideChat} />
					<div className="AppContent">
						<Route exact path="/" component={Splash} />
						<Route path="/microsoft" render={(props) => <Guide {...props} type="microsoft" />} />
						<Route path="/gsuite" render={(props) => <Guide {...props} type="gsuite" />} />
						{this.state.hideChat || <Chat hideChat={this.hideChat} />}
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
