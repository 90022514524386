import React, { Component } from 'react';
import './GuideContent.scss';

class GuideContent extends Component {
	render() {
		console.log('bbb')
		return (
			<div className="guide-content">
				<h1>{this.props.section.title}</h1>
				<p>{this.props.section.text}</p>
			</div>
		)
	}
}

export default GuideContent;
