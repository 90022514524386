import React, { Component } from 'react';
import './ServiceButton.scss';

import Icon from '@mdi/react'

class ServiceButton extends Component {
	constructor() {
		super();
		this.state = {};
	}

	render ()  {
		return (
			<div className={this.props.class + ' button'} onClick={this.props.onClick}>
				{this.props.icon ? <Icon path={this.props.icon} size={this.props.size} /> : null}
				<span>{this.props.text}</span>
			</div>
		)
	}
}
export default ServiceButton;
