import React, { Component } from 'react';
import './GuideIndexElement.scss';

class GuideIndexElement extends Component {
	render() {
		return (
			<div className={this.props.className} onClick={this.props.onClick}>
				<span>{this.props.text}</span>
			</div>
		)
	}
}

export default GuideIndexElement;
