import React, { Component } from 'react';
import './Guide.scss';

import GuideIndex from './GuideIndex';
import GuideContent from './GuideContent';

class Guide extends Component {
	guideList = { // Debug
		microsoft: {
			title: 'Microsoft 365',
			sections: [
				{
					title: 'Agregar una firma a los mensajes',
					text: (<div>
						Cree una firma para los mensajes de correo que puede agregar automáticamente a todos los mensajes salientes o manualmente a algunos concretos.
						<ol>
							<li>Inicie sesión en Outlook en la web.</li>
							<li>Vaya a <b>Configuración</b> > <b>Ver todas las opciones de Outlook</b> > <b>Redactar y responder</b>.</li>
							<li>Bajo <b>Firma de correo electrónico</b>, escriba su firma y use las opciones de formato disponibles para cambiar su aspecto.</li>
							<ul>
								<li>Si desea que su firma aparezca en la parte inferior de todos los nuevos mensajes de correo electrónico que redacte, seleccione la casilla <b>Incluir mi firma automáticamente en los mensajes que redacte</b>.</li>
								<li>Si desea mostrar su firma en los mensajes que reenvíe o responda, seleccione la casilla <b>Incluir automáticamente mi firma en los mensajes que reenvíe o responda</b>.</li>
								<li>Si no selecciona estas opciones, puede agregar su firma manualmente a un mensaje seleccionado.</li>
							</ul>
						<li>Haga clic en <b>Guardar</b> cuando haya terminado.</li>
						</ol>
					</div>)
				},
				{
					title: 'Compartir archivos y carpetas de OneDrive',
					text: (<div>
						Puede usar OneDrive para compartir fotos, documentos de Microsoft Office, otros archivos y carpetas enteras con otros usuarios. Los archivos y carpetas que almacene en OneDrive son privados hasta que decida compartirlos. Asimismo, puede dejar de compartirlos en cualquier momento.
						<ol>
							<li>Vaya al sitio web de OneDrive e inicie sesión con su cuenta.</li>
							<li>Seleccione el archivo que desee compartir y elija <b>Compartir</b>.</li>
							<li>Indique los nombres de las personas o grupos con las que desea compartir archivos y escriba un mensaje, si así lo desea.</li>
							<li>(Opcional) Haga clic en la lista desplegable para cambiar el tipo de vínculo. Se abre el panel de detalles, donde puede cambiar quién puede tener acceso al vínculo y si las personas pueden editar el elemento que está compartiendo.<br /><br />Opciones de <b>Usuarios que pueden utilizar este vínculo</b> (estas opciones varían según la configuración de administración):
							<ul>
								<li><b>Cualquiera</b> da acceso a cualquier persona que reciba este vínculo, tanto si lo han recibido directamente de su parte o se lo ha reenviado otra persona. Se incluyen personas de fuera de su organización.</li>
								<li><b>Personas de (su organización)</b> da acceso al vínculo a todas las personas de su organización, tanto si lo han recibido directamente de su parte o se lo ha reenviado otra persona.</li>
								<li><b>Usuarios con acceso existente</b> puede ser usada por personas que ya tienen acceso al documento o carpeta. No cambia los permisos en el elemento. Use esta opción si desea enviar un vínculo a alguien que ya tiene acceso.</li>
								<li><b>Determinadas personas</b> da acceso a las personas que especifique, aunque es posible que otras personas ya tengan acceso. Si alguien reenvía la invitación para uso compartido, solo aquellas personas que ya tengan acceso al elemento podrán usar el vínculo.</li>
							</ul>
							La opción Permitir edición está activada de manera predeterminada. Si desea que los usuarios solo puedan ver sus archivos, anule la selección de la casilla.<br /><br />Cuando haya terminado, haga clic en Aplicar.</li>
							<li>Cuando esté listo para enviar el vínculo, seleccione Enviar.</li>
						</ol>
					</div>)
				},
				{
					title: 'Microsoft Sección 3',
					text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et arcu sed elit vulputate mattis ultrices quis urna. Donec bibendum, orci nec porttitor eleifend, justo justo dapibus diam, sit amet posuere sapien orci vel sapien. Mauris malesuada justo at rutrum blandit. Fusce lacinia risus eu erat ornare iaculis. Integer varius odio nec sem tempor, nec vestibulum sapien pretium. Pellentesque iaculis tortor justo. Nunc eu lorem nisi. Vestibulum vel augue sed sapien dignissim vehicula in vitae lacus. Vivamus tellus orci, varius non nisl vitae, consectetur dapibus enim. Curabitur tempus, velit non malesuada pretium, sem tellus ultricies ipsum, vehicula ultricies ligula augue quis diam.\n\nFusce condimentum leo nulla, tristique tempus arcu ullamcorper sit amet. Etiam feugiat sapien et dui tempor, ut pulvinar sem porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt nec mauris commodo aliquam. Nunc laoreet eros vitae auctor mollis. Aliquam placerat pellentesque orci, quis fringilla nulla placerat eget. Morbi aliquam ante ut leo sagittis porttitor. Quisque commodo nisi ligula, ut pretium ligula vulputate sed. Aliquam ultrices nisl at elit malesuada varius. Vivamus eget justo in urna convallis volutpat. Integer ac semper magna, a ultricies odio. Morbi ac eleifend turpis. Duis varius metus et quam commodo, eget tristique lectus ullamcorper. Nulla auctor imperdiet nunc vitae ultricies. Donec quis faucibus orci, vitae dictum ligula.'
				}
			]
		},
		gsuite: {
			title: 'G Suite',
			sections: [
				{
					title: '¿Cómo puedo cambiar de un producto de G Suite a otro?',
					text: (<div>Hay dos formas de cambiar de un producto de G Suite a otro:<ul><li>En la esquina superior de cualquier página de Google, haz clic en Aplicaciones apps y elige un producto de G Suite.</li><li>Escribe nombre-producto.google.com en la barra de direcciones. Por ejemplo, drive.google.com, calendar.google.com, etc.</li></ul></div>)
				},
				{
					title: '¿Cómo puedo cambiar la contraseña?',
					text: (<ol><li>Inicia sesión en Mi cuenta.</li><li>En la sección "Inicio de sesión y seguridad", selecciona <b>Inicio de sesión en Google</b>.</li><li>Haz clic en <b>Contraseña</b> y sigue las instrucciones a partir de ahí.</li></ol>)
				},
				{
					title: 'G Suite Sección 3',
					text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et arcu sed elit vulputate mattis ultrices quis urna. Donec bibendum, orci nec porttitor eleifend, justo justo dapibus diam, sit amet posuere sapien orci vel sapien. Mauris malesuada justo at rutrum blandit. Fusce lacinia risus eu erat ornare iaculis. Integer varius odio nec sem tempor, nec vestibulum sapien pretium. Pellentesque iaculis tortor justo. Nunc eu lorem nisi. Vestibulum vel augue sed sapien dignissim vehicula in vitae lacus. Vivamus tellus orci, varius non nisl vitae, consectetur dapibus enim. Curabitur tempus, velit non malesuada pretium, sem tellus ultricies ipsum, vehicula ultricies ligula augue quis diam.\n\nFusce condimentum leo nulla, tristique tempus arcu ullamcorper sit amet. Etiam feugiat sapien et dui tempor, ut pulvinar sem porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt nec mauris commodo aliquam. Nunc laoreet eros vitae auctor mollis. Aliquam placerat pellentesque orci, quis fringilla nulla placerat eget. Morbi aliquam ante ut leo sagittis porttitor. Quisque commodo nisi ligula, ut pretium ligula vulputate sed. Aliquam ultrices nisl at elit malesuada varius. Vivamus eget justo in urna convallis volutpat. Integer ac semper magna, a ultricies odio. Morbi ac eleifend turpis. Duis varius metus et quam commodo, eget tristique lectus ullamcorper. Nulla auctor imperdiet nunc vitae ultricies. Donec quis faucibus orci, vitae dictum ligula.'
				}
			]
		}
	}

	navigate = (index) => {
		this.setState({ section: index });
	}

	constructor(props) {
		super(props);
		this.state = {
			section: 0
		};
		this.guide = this.guideList[this.props.type];
		this.sections = this.guide.sections.map((section) => {
			return section.title;
		});
	};

	render() {
		return (
			<div className="guide-wrapper">
				<GuideIndex title={this.guide.title} sections={this.sections} navigate={this.navigate} selected={this.state.section} />
				<GuideContent section={this.guide.sections[this.state.section]} />
			</div>
		)
	}
}
export default Guide;
