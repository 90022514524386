import firebase from 'firebase';
import 'firebase/auth';

class Authentication {
	constructor() {
		this.auth = firebase.auth;
	}

	// Primero se implementa el metodo de login

	login = (email, password) => {
		this.auth.signInWithEmailAndPassword(email, password);
	};

	// Segundo se implementa el metodo de logout

	logout = () => {
		this.auth.signOut();
	};
}

export default Authentication;
