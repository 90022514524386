import React, { Component } from 'react';
import './GuideIndex.scss';

import GuideIndexElement from './GuideIndexElement';

class GuideIndex extends Component {
	render() {
		return (
			<div className="index-wrapper">
				<div className="index-title">
					<span>{this.props.title}</span>
				</div>
				{
					this.props.sections.map((section, i) => {
						return (
							<GuideIndexElement key={i} text={section} onClick={() => this.props.navigate(i)} className={'indexElement' + (this.props.selected === i ? ' selected' : '')} />
						)
					})
				}
			</div>
		)
	}
}
export default GuideIndex;
